@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply text-gray-500 dark:text-gray-400 text-sm bg-gray-100 dark:bg-gray-900 leading-normal;
        -webkit-font-smoothing: antialiased;
    }

    h1,
    .h1 {
        @apply text-4xl font-bold text-gray-900 dark:text-gray-100;
    }

    h2,
    .h2 {
        @apply text-3xl font-bold text-gray-900 dark:text-gray-100;
    }

    h3,
    .h3 {
        @apply text-2xl font-semibold text-gray-900 dark:text-gray-100;
    }

    h4,
    .h4 {
        @apply text-xl font-semibold text-gray-900 dark:text-gray-100;
    }

    h5,
    .h5 {
        @apply text-lg font-semibold text-gray-900 dark:text-gray-100;
    }

    h6,
    .h6 {
        @apply text-base font-semibold text-gray-900 dark:text-gray-100;
    }

    hr {
        @apply border-gray-200 dark:border-gray-600;
    }

    .heading-text {
        @apply text-gray-900 dark:text-gray-100;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.establishment-modal-body .select__control {
    border-color: #d1d5db !important;
}

.establishment-modal-body .error-shadow .select__control {
    box-shadow: 0 0 0 0px #d1d5db !important;
}

.establishment-modal-phonenumber-error {
    margin-top: -15px;
    margin-bottom: 10px;
    color: #ef4444;
}

.unit-modal-body .form-item {
    margin-bottom: 1rem !important;
}

.disableTreeSelect .tag-list {
    background: #efefef4d !important;
}

.disableTreeSelect .tag-list .tag-item {
    cursor: not-allowed !important;
}

.disableTreeSelect .tag-list input {
    cursor: not-allowed !important;
}

/* Add this to your CSS file or within a <style> block if using CSS-in-JS */
.custom-scrollbar {
    @apply overflow-y-scroll overflow-x-hidden;
}

/* Custom scrollbar width */
.custom-scrollbar::-webkit-scrollbar {
    width: 8px; /* Adjust width as needed */
}

/* Custom scrollbar track */
.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
}

/* Custom scrollbar thumb */
.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar thumb color */
    border-radius: 40px; /* Rounded corners */
}

/* Scrollbar thumb on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Hover color */
}



#product-search-input {
    border-right: none !important;
}

#product-search-clear {
 border-left:none !important;
}

#product-search-clear:hover {
    background-color: #fff;
}

html, body {
    overscroll-behavior: none;
}

header .text-2xl.header-action-item.header-action-item-hoverable {
    display: none;
}